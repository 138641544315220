<template>
    <v-app id="autenticar">
        <v-container d-fluid id="autenticar_ceritificado">
            <v-row no-gutters class="d-flex justify-space-between">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                    <v-card class="elevation-3 pa-3">
                        <v-card-title class="text-center justify-center">
                            <div>
                                <v-img src="@/assets/image/LogoGris.png" contain width="350px" height="auto"></v-img>
                                <h3 class="my-4 primary--text">AUTENTICAR CERTIFICADO</h3>
                            </div>
                        </v-card-title>
                        <v-card-text>

                            <template v-if="typeof this.token !== 'undefined'">
                                <template v-if="this.estado == 'success'">
                                    <v-alert type="success" dense class="mb-3 text-uppercase">
                                        Certificado Autenticado con los siguientes datos.
                                    </v-alert>
                                    <div class="border">
                                        <v-chip color="info">Estudiante:</v-chip>
                                        <div>{{ objData.persona.nombre }} {{ objData.persona.apellido }}</div>
                                    </div>
                                    <v-divider class="mb-2"></v-divider>
                                    <div>
                                        <v-chip color="info">Curso:</v-chip>
                                        <div>{{ objData.curso.nombre }}</div>
                                    </div>
                                    <v-divider class="mb-2"></v-divider>
                                    <div>
                                        <v-chip color="info">Unidad Operativa:</v-chip>
                                        <div>{{ objData.unidadOperativa.unidad_operativa }}</div>
                                    </div>
                                    <v-divider class="mb-2"></v-divider>
                                    <div>
                                        <v-chip color="info">Modo de formación:</v-chip>
                                        <div>{{ objData.unidadOperativa.modo_formacion }}</div>
                                    </div>
                                    <v-divider class="mb-2"></v-divider>
                                    <div>
                                        <v-chip color="info">Registro No:</v-chip>
                                        <div>{{ objData.curso.registro }}</div>
                                    </div>
                                </template>
                                <template v-if="this.estado == 'error'">
                                    <v-alert type="error">{{ mensaje }}</v-alert>
                                </template>
                            </template>
                            <template v-else>
                                <v-alert type="error">No se puede Autenticar sin el parametro de seguridad. Escanea
                                    nuevamente el Código QR en el Certificado.
                                </v-alert>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>

</template>

<script>

export default {
    name: 'AUtenticarCertificado',
    data() {
        return {
            loading: false,
            objData: {},
            token: this.$route.query.token,
            mensaje: "",
            estado: null
        }
    },
    methods: {

        autenticar() {

            if (typeof this.token !== "undefined") {

                this.loading = true;

                let params = {
                    token: this.token
                };

                window.axios.get('/certificados/autenticar', {params}).then(async response => {
                    if (response.data.success == true) {
                        this.objData = response.data.data;
                        this.estado = "success";

                    } else if (response.data.success === false) {

                        this.mensaje = response.data.message;
                        this.estado = "error";
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.estado = "error";
                        this.mensaje = "No se pudo Autenticar";
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
    },
    created() {
        this.autenticar();
    }
}

</script>

<style src="./Autenticar.scss" lang="scss"/>
