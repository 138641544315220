import { render, staticRenderFns } from "./AutenticarCertificado.vue?vue&type=template&id=2a27e388&"
import script from "./AutenticarCertificado.vue?vue&type=script&lang=js&"
export * from "./AutenticarCertificado.vue?vue&type=script&lang=js&"
import style0 from "./Autenticar.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports